import React, { useContext, useEffect } from 'react';
import { MainContainer } from '../../components/MainContainer';
import { TwoContainerComponent } from '../../components/Containers/TwoRowContainer';
import { FilterComponent } from './components/FilterComponent';
import { JustifyIncidenceContextManager } from '../../context/wrapperContext/justifyIncidence/JustifyIncidenceContext';
import { TableComponent } from './components/TableComponent';
import { ModalComponent } from './components/ModalComponent';
import { SearchComponent } from '../../components/SearchComponent';
import { MultiSelectComponent } from '../../components/MultiSelect/MultiSelectComponent';
import { incidencesToJustifyModal } from '../../utils/utils';
import { Button } from 'primereact/button';
import { Toast } from '../../components/toast';
import { useJustifyIncidences } from '../../hooks/justifyIncidences/useJustifyIncidences';
import { IconsPanelControl } from '../../components/IconsPanelControl';
import { ModalErrorsGeneralComponent } from '../../components/Modal/ModalErros';

export const JustifyIncidence = () => {
	const serviceContext = useContext(JustifyIncidenceContextManager);
	const { getJustifyTypes } = useJustifyIncidences();
	useEffect(() => {
		getJustifyTypes();
	}, []);

	const leftComponent = () => {
		return <FilterComponent service={serviceContext} />;
	};

	const renderMultiSelect = (
		id,
		placeholder,
		optionLabel,
		value,
		options,
		onChange,
		otherPlaceHolder
	) => {
		return (
			<MultiSelectComponent
				id={id}
				value={value}
				optionLabel={optionLabel}
				options={options}
				onChange={onChange}
				placeholder={placeholder}
				otherPlaceHolder={otherPlaceHolder}
			/>
		);
	};
	const rightComponent = () => {
		return (
			<div className='h-full flex flex-col w-full ml-1 border rounded-md shadow-2xl'>
				<div className='flex w-full h-12 flex-row px-1 pt-1'>
					<div className='h-full flex-1 basis-4/12'>
						<SearchComponent
							clearTextLabel={serviceContext.clearTextLabel}
							onChange={serviceContext.onGlobalFilterChange}
							value={serviceContext.globalFilterValue}
						/>
					</div>
					<div className='h-full flex-1 basis-2/12 '>
						{renderMultiSelect(
							'typeIncidents',
							'Seleccione incidencias',
							'name',
							serviceContext.typeIncidents,
							incidencesToJustifyModal,
							serviceContext.handleSetIncidences,

							'Todos los tipos de incidencias'
						)}
					</div>
					<div className='flex h-full flex-auto  px-1 gap-1'>
						<Button
							size='small'
							label='Justificación Masiva'
							className='!flex w-2/4 !h-full '
							aria-label='Submit'
							type='submit'
							onClick={() => {
								serviceContext.tableDataQueryFilterList.length > 0
									? serviceContext.handleSelectionTable(
											serviceContext.tableDataQueryFilterList,
											false
									  )
									: Toast('info', 'No hay datos para justificar');
							}}
						/>
						<Button
							label='Deshacer Justificantes'
							className='!flex !w-2/4 !h-full'
							aria-label='Submit'
							type='submit'
							onClick={() => {
								serviceContext.tableDataQueryFilterList.length > 0
									? serviceContext.undoJustification(
											serviceContext.tableDataQueryFilterList,
											false
									  )
									: Toast('info', 'No hay justificantes para deshacer');
							}}
						/>
					</div>
					<div className='h-full w-4 flex-none'>
						<IconsPanelControl iconsData={serviceContext.iconsDataPanel} />
					</div>
				</div>

				<div className='flex w-full h-full flex-grow  relative p-1'>
					<TableComponent service={serviceContext} />
				</div>
			</div>
		);
	};
	const renderBodyMainContainer = () => {
		return (
			<TwoContainerComponent
				leftComponent={leftComponent()}
				rightComponent={rightComponent()}
			/>
		);
	};
	return (
		<MainContainer cancelTittle>
			<div className='flex w-full h-full flex-row p-1 border relative'>
				{renderBodyMainContainer()}
				<ModalComponent service={serviceContext} />
				<ModalErrorsGeneralComponent
					columnsTable={serviceContext.dataTableModalErrors.columnsTable}
					dataTable={serviceContext.dataTableModalErrors.dataTable}
					fieldSort='fullName'
					onCloseModal={serviceContext.onCloseModalErrors}
					openModal={serviceContext.dataTableModalErrors.openModalErrors}
					titleModal={`Se encontraron errores al ${
						serviceContext.modalData.onJustify
							? 'justificar'
							: 'deshacer justificantes'
					}`}
				/>
			</div>
		</MainContainer>
	);
};
