import { useReducer } from 'react';
import {
	getJustifyIncidences_IS,
	getJustifyIncidencesRed,
} from '../../../business/reducers/JustifyIncidences/getJustifyIncidencesReducer';
import { actionTypesOperations } from '../../../business/constans/operation';
import {
	getJustifyIncidencesTypes_IS,
	getJustifyIncidencesTypesRed,
} from '../../../business/reducers/JustifyIncidences/getJustifyTypesReducer';
import {
	postJustifyIncidences_IS,
	postJustifyIncidencesRed,
} from '../../../business/reducers/JustifyIncidences/postJustifyIncidencesReducer';
import {
	postUndoJustifyIncidences_IS,
	postUndoJustifyIncidencesRed,
} from '../../../business/reducers/JustifyIncidences/postUndoJustifyIncidencesReducer';
const {
	GET_JUSTIFY_INCIDENCES,
	GET_JUSTIFY_INCIDENCES_TYPES,
	POST_JUSTIFY_INCIDENCES,
	POST_UNDO_JUSTIFY_INCIDENCES,
} = actionTypesOperations;
export const useGetJustifyIncidencesState = () => {
	const [stateGetJustifyIncidences, dispatch] = useReducer(
		getJustifyIncidencesRed,
		getJustifyIncidences_IS
	);

	const getJustifyIncidencesInit_DP = () => {
		dispatch({
			type: GET_JUSTIFY_INCIDENCES.BASE,
		});
	};
	const getJustifyIncidencesSuccess_DP = (data) => {
		dispatch({
			type: GET_JUSTIFY_INCIDENCES.SUCCESS,
			payload: data,
		});
	};
	const getJustifyIncidencesError_DP = (data = '') => {
		dispatch({
			type: GET_JUSTIFY_INCIDENCES.ERROR,
			payload: data,
		});
	};

	return {
		stateGetJustifyIncidences,
		getJustifyIncidencesInit_DP,
		getJustifyIncidencesSuccess_DP,
		getJustifyIncidencesError_DP,
	};
};

export const useJustifyIncidencesTypesSTate = () => {
	const [stateJustifyIncidencesTypes, dispatchJustifyIncidencesTypes] =
		useReducer(getJustifyIncidencesTypesRed, getJustifyIncidencesTypes_IS);

	const getJustifyIncidencesTypesInit_DP = () => {
		dispatchJustifyIncidencesTypes({
			type: GET_JUSTIFY_INCIDENCES_TYPES.BASE,
		});
	};
	const getJustifyIncidencesTypesSuccess_DP = (data) => {
		dispatchJustifyIncidencesTypes({
			type: GET_JUSTIFY_INCIDENCES_TYPES.SUCCESS,
			payload: data,
		});
	};
	const getJustifyIncidencesTypesError_DP = (data = '') => {
		dispatchJustifyIncidencesTypes({
			type: GET_JUSTIFY_INCIDENCES_TYPES.ERROR,
			payload: data,
		});
	};

	return {
		stateJustifyIncidencesTypes,
		getJustifyIncidencesTypesInit_DP,
		getJustifyIncidencesTypesSuccess_DP,
		getJustifyIncidencesTypesError_DP,
	};
};

export const usePostJustifyIncidencesState = () => {
	const [statePostJustifyIncidences, dispatchPostJustifyIncidences] =
		useReducer(postJustifyIncidencesRed, postJustifyIncidences_IS);

	const postJustifyIncidencesInit_DP = () => {
		dispatchPostJustifyIncidences({
			type: POST_JUSTIFY_INCIDENCES.BASE,
		});
	};

	const postJustifyIncidencesSuccess_DP = (data) => {
		dispatchPostJustifyIncidences({
			type: POST_JUSTIFY_INCIDENCES.SUCCESS,
			payload: data,
		});
	};

	const postJustifyIncidencesError_DP = (data = '') => {
		console.log('data', data);
		dispatchPostJustifyIncidences({
			type: POST_JUSTIFY_INCIDENCES.ERROR,
			payload: data,
		});
	};
	const postJustifyIncidencesClear_DP = () => {
		dispatchPostJustifyIncidences({
			type: POST_JUSTIFY_INCIDENCES.CLEAR,
		});
	};

	return {
		statePostJustifyIncidences,
		postJustifyIncidencesInit_DP,
		postJustifyIncidencesSuccess_DP,
		postJustifyIncidencesError_DP,
		postJustifyIncidencesClear_DP,
	};
};
export const usePostUndoJustifyIncidencesState = () => {
	const [statePostUndoJustifyIncidences, dispatchPostUndoJustifyIncidences] =
		useReducer(postUndoJustifyIncidencesRed, postUndoJustifyIncidences_IS);

	const postUndoJustifyIncidencesInit_DP = () => {
		dispatchPostUndoJustifyIncidences({
			type: POST_UNDO_JUSTIFY_INCIDENCES.BASE,
		});
	};

	const postUndoJustifyIncidencesSuccess_DP = (data) => {
		dispatchPostUndoJustifyIncidences({
			type: POST_UNDO_JUSTIFY_INCIDENCES.SUCCESS,
			payload: data,
		});
	};

	const postUndoJustifyIncidencesError_DP = (data = '') => {
		dispatchPostUndoJustifyIncidences({
			type: POST_UNDO_JUSTIFY_INCIDENCES.ERROR,
			payload: data,
		});
	};

	const postUndoJustifyIncidencesClear_DP = () => {
		dispatchPostUndoJustifyIncidences({
			type: POST_UNDO_JUSTIFY_INCIDENCES.CLEAR,
		});
	};

	return {
		statePostUndoJustifyIncidences,
		postUndoJustifyIncidencesInit_DP,
		postUndoJustifyIncidencesSuccess_DP,
		postUndoJustifyIncidencesError_DP,
		postUndoJustifyIncidencesClear_DP,
	};
};
