import { Column } from 'jspdf-autotable';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { GeneralNames } from '../../../namesConstants/names';
import { PropTypes } from 'prop-types';

export const TableComponent = ({ service }) => {
	const headerClassNameColumn =
		'hover:!text-success  !bg-secondary-PCS004 !text-secondary-PCS001 !font-semibold !border-r-2 !border-secondary-PCS003 !font-bold !text-[14px] !font-karla ';
	const bodyClassName =
		'!border-b-0 !border-secondary-PCS004 !text-black !justify-start ';

	const dynamicColumnsTableOpen = service.dynamicColumns.map((col, i) => {
		return (
			<Column
				columnKey={i}
				style={{ width: '150px' }}
				key={col.field}
				field={col.field}
				header={col.header}
				frozen={col.frozen}
				sortable={col.sortable}
				headerClassName={headerClassNameColumn}
				bodyClassName={bodyClassName}
				alignFrozen={col.alignFrozen}
				body={(rowData) => {
					return col?.body !== undefined
						? col?.body(rowData[col.field], col.field, service, rowData)
						: rowData[col.field];
				}}
			/>
		);
	});
	return (
		<div className='flex w-full h-full relative'>
			<DataTable
				key={'keyTable'}
				value={service.tableDataQueryFilterList}
				paginator
				rows={25}
				first={0}
				sortField={service.sort.field}
				sortOrder={service.sort.order}
				scrollable
				size='small'
				scrollHeight='flex'
				filters={service.filters}
				emptyMessage={GeneralNames.GeneralEmptyMessage}
				globalFilterFields={['code', 'name', 'department']}
				rowsPerPageOptions={[25, 50, 75, 100]}
				className='!flex !flex-col !w-full !h-full !relative !bg-transparent '
				tableStyle={{
					height: '100% !important',
					minHeight: '100% !important',
					background: 'none',
					backgroundColor: 'none',
				}}
				selection={service.toSend}
				selectionMode='checkbox'
				onSelectionChange={(e) => service.setToSend(e.value)}
				onSort={(e) => service.setSort(e.multiSortMeta)}
				multiSortMeta={service.sort}
				sortMode='multiple'
				columnResizeMode='fit'
				tableClassName='!overflow-scroll !flex-col !absolute  inset-0 '
				rowClassName='hover:!bg-[#3b82f6] !border-r !border-secondary-PCS004  '
				paginatorClassName='!border-t !border-border !justify-start !text-xs !top-0 flex bg-withe'
				paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown '
				currentPageReportTemplate='Total de registros {totalRecords}'
				responsiveLayout='scroll'>
				{dynamicColumnsTableOpen}
			</DataTable>
		</div>
	);
};

TableComponent.propTypes = {
	service: PropTypes.object,
};
