import * as yup from 'yup';

const timeRegex = /^(0?[0-9]|1[0-2]):([0-5][0-9]) (AM|PM)$/;

const ScheduleSchema = yup.object().shape({
	workShiftId: yup.string().required('Turno de nómina requerido'),
	starts: yup
		.string()
		.matches(timeRegex, 'Hora inválida (Formato correcto: 12:30 PM)')
		.required('Debe seleccionar una hora de inicio'),
	ends: yup
		.string()
		.matches(timeRegex, 'Hora inválida (Formato correcto: 12:30 PM)')
		.required('Debe seleccionar una hora de salida'),
	code: yup
		.string()
		.max(8, 'Máximo 8 caracteres')
		.required('Clave requerida')
		.matches(
			/[a-zA-Z0-9]{1,8}/,
			'Código inválido, sólo se permiten caracteres alfanuméricos'
		),
	description: yup.string().required('Nombre requerido'),
	restDays: yup.array(),
});
export default ScheduleSchema;
