import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { CustomTable } from '../../Table/CustomTable';
import { ErrorsNames, GeneralNames } from '../../../namesConstants/names';

import { headerClassName } from '../../../assets/customStyles';
import { bodyClassName } from '../../../screens/Operation/EmployeeManagement/Const/Constants';
import { Column } from 'primereact/column';

export const ModalErrorsGeneralComponent = ({
	columnsTable = [],
	dataTable = [],
	openModal = false,
	onCloseModal = () => {},
	fieldSort = 'fullName',
	titleModal = '',
}) => {
	const [sort, setSort] = useState([{ field: fieldSort, order: 1 }]);

	const renderBody = (rowData) => {
		return <p title={rowData}>{rowData}</p>;
	};

	const content = () => {
		return (
			<CustomTable
				sortField='fullName'
				value={dataTable}
				header={undefined}
				style={{ height: '100%', width: '100%', overflow: 'auto' }}
				filters={undefined}
				responsiveLayout='scroll'
				globalFilterFields={['error']}
				emptyMessage={GeneralNames.GeneralEmptyMessage}
				currentPageReportTemplate={ErrorsNames.CurrentPageReportTemplate}
				resizableColumns
				columnResizeMode='fit'
				setSort={setSort}
				sort={sort}>
				{columnsTable.map((column) => {
					return (
						<Column
							key={column.field}
							headerClassName={headerClassName}
							style={column.style}
							field={column.field}
							header={column.header}
							sortable
							bodyClassName={bodyClassName}
							body={(rowData) => {
								return renderBody(rowData[column.field]);
							}}
						/>
					);
				})}
			</CustomTable>
		);
	};

	return (
		<Dialog
			header={titleModal}
			visible={openModal}
			modal
			style={{ width: '80vw', position: 'relative', height: '60vh' }}
			onHide={() => onCloseModal()}>
			{content()}
		</Dialog>
	);
};
