import { TableNames } from '../../../namesConstants/names';
import { formatDate } from '../../../utils/utils';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';

function getDaysBetweenDates(startDate, endDate) {
	const days = [];
	let currentDate = new Date(startDate);

	while (currentDate <= endDate) {
		const newDate = new Date(currentDate);
		const formattedDate = formatDate(newDate);

		days.push(formattedDate);
		currentDate.setDate(currentDate.getDate() + 1);
	}

	return days;
}

export const bgColors = {
	timeAttendance: 'bg-[#65CD81]',
	quantityAttendance: 'bg-[#65CD81]',
	1: 'bg-[#65CD81]',
	timeDayOff: 'bg-[#CCD8E2]',
	quantityDayOff: 'bg-[#CCD8E2]',
	2: 'bg-[#CCD8E2]',
	timeAbscence: 'bg-[#FF8585]',
	quantityAbscence: 'bg-[#FF8585]',
	3: 'bg-[#FF8585]',
	timeInhability: 'bg-[#757575]',
	quantityInhability: 'bg-[#757575]',
	4: 'bg-[#757575]',
	timeDelay: 'bg-[#FFE485]',
	quantityDelay: 'bg-[#FFE485]',
	5: 'bg-[#FFE485]',
	timeVacation: 'bg-[#003A70]',
	quantityVacation: 'bg-[#003A70]',
	6: 'bg-[#003A70]',
	timePermission: 'bg-[#C9D2FF]',
	quantityPermission: 'bg-[#C9D2FF]',
	7: 'bg-[#C9D2FF]',
	timeRestDay: 'bg-[#896BDC]',
	quantityRestDay: 'bg-[#896BDC]',
	9: 'bg-[#896BDC]',
};

export const columnChecksIncidents = (rowData, field, service, item) => {
	let splitRowData = rowData ? rowData.split('|') : 'N/D';

	const type = rowData ? splitRowData[1].replace(/ /g, '') : '';
	const dataColor = rowData ? bgColors[Number(type)] || '' : '';
	const text = rowData ? splitRowData[0] : splitRowData;
	const titleText =
		rowData && splitRowData[0] !== ' '
			? splitRowData[4]
			: 'No se encontró información';

	const labelText =
		text === undefined || text === null || text === ' ' ? 'N/D' : text;

	return (
		<div
			onClick={() => service.onClickDate(field, item, type)}
			className='flex items-center cursor-pointer  w-full h-full'>
			<div className={`h-1 w-1 rounded-full ${dataColor}`} />
			<label
				title={titleText}
				className='text-small-font-size text-[14px] ml-[2px] truncate'>
				{labelText}
			</label>
		</div>
	);
};

export const columnData = (rowData) => {
	return (
		<label
			title={rowData}
			className='text-small-font-size text-[14px]  flex truncate '>
			{rowData}
		</label>
	);
};
export const columnDataClick = (rowData, field, service, row) => {
	return (
		<label
			onClick={() => service.handleSelectionTable([row], true)}
			title={rowData}
			className='text-small-font-size text-[14px] flex truncate cursor-pointer text-blue-600 underline'>
			{rowData}
		</label>
	);
};
const initArrayColumns = [
	{
		field: 'code',
		header: 'Código',
		sortable: true,
		frozen: true,
		alignFrozen: 'left',
		width: { flexGrow: 2, flexBasis: '80px' },
		body: columnDataClick,
	},
	{
		field: 'name',
		header: 'Nombre',
		sortable: true,
		frozen: true,
		alignFrozen: 'left',
		width: { flexGrow: 2, flexBasis: '80px' },
		body: columnDataClick,
	},
	{
		field: 'department',
		header: TableNames.TableDepartment,
		sortable: true,
		frozen: false,
		alignFrozen: 'left',
		width: { flexGrow: 2, flexBasis: '80px' },
		body: columnData,
	},
];

export function DynamicColumnsJustifyIncidences(startDate, endDate) {
	const daysBetweenDates = getDaysBetweenDates(startDate, endDate);

	const arregloDeFechas = daysBetweenDates.map((fecha, index) => ({
		field: fecha,
		header: fecha,
		sortable: false,
		frozen: false,
		width: { flexGrow: 2, flexBasis: '30px' },
		body: columnChecksIncidents,
	}));

	const arrayDataColumns = [...initArrayColumns, ...arregloDeFechas];

	return arrayDataColumns;
}

export function processIncidencesToTable(dataArray, date = '') {
	return dataArray.flatMap((item) => {
		return Object.entries(item.incidences || {}).flatMap(
			([incidenceDate, value]) => {
				if (value) {
					const parts = value.split(' | ');
					if (parts.length === 7 && (parts[1] === '3' || parts[1] === '5')) {
						if (date !== '' && incidenceDate !== date) {
							return [];
						}

						const _date = moment(incidenceDate, 'DD/MM/YYYY').format(
							'DD/MM/YYYY'
						);

						return {
							collaboratorCode: item.employeeId,
							colaboraInstanceId: item.colaboraInstanceId,
							attendanceId: parts[2],
							name: item.name,
							date: _date, //
							code: item.code,
							incidentId: parts[2],
							incidence: parts[0],
							incidenceType: parts[1],
							justification: false,
							justificationType: null,
							statusIncidence: parts[3],
							labelText: parts[4],
							originalIncidence: parts[5],
							incidenceColorType: parts[6],
						};
					}
				}
				return [];
			}
		);
	});
}
export function processUndoIncidencesToTable(dataArray, date = '') {
	return dataArray.flatMap((item) => {
		return Object.entries(item.incidences || {}).flatMap(
			([incidenceDate, value]) => {
				if (value) {
					const parts = value.split(' | ');
					if (parts.length === 7 && parts[1] === '7') {
						if (date !== '' && incidenceDate !== date) {
							return [];
						}

						const _date = moment(incidenceDate, 'DD/MM/YYYY').format(
							'DD/MM/YYYY'
						);

						return {
							collaboratorCode: item.employeeId,
							colaboraInstanceId: item.colaboraInstanceId,
							attendanceId: parts[2],
							name: item.name,
							date: _date, //
							code: item.code,
							incidentId: parts[2],
							incidence: parts[0],
							incidenceType: parts[1],
							justification: false,
							justificationType: null,
							statusIncidence: parts[3],
							labelText: parts[4],
							originalIncidence: parts[5],
							incidenceColorType: parts[6],
						};
					}
				}
				return [];
			}
		);
	});
}

export const enumsTypesIncidences = {
	1: ' Asistencia',
	2: 'Día no laborable',
	3: 'Falta',
	4: 'Incapacidad',
	5: ' Retardo ',
	6: 'Vacaciones',
	7: 'Permiso',
	8: ' Otro',
	9: ' Día de descanso',
	10: 'Sin horario',
};

const renderBody = (rowData) => {
	return rowData;
};

const actualIncidenceBody = (rowData, service) => {
	const { onJustify } = service.modalData;
	return (
		<>
			<div
				className={`h-1 w-1 rounded-full ${
					bgColors[
						Number(rowData[onJustify ? 'incidenceType' : 'incidenceColorType'])
					]
				}`}
			/>
			<label
				title={
					enumsTypesIncidences[
						rowData[onJustify ? 'incidenceType' : 'incidenceColorType']
					]
				}
				className='text-small-font-size text-[14px] !ml-[4px] truncate  flex '>
				{rowData.originalIncidence}
			</label>
			{rowData.statusIncidence === 3 ? (
				<label className='text-small-font-size text-[14px] !ml-[4px] truncate  flex '>
					{` (${processStatus[rowData.statusIncidence]}) `}
				</label>
			) : null}
		</>
	);
};

function justifyIncidenceBody(item, service) {
	return service.modalData.onJustify
		? renderDropComponent(
				'typePeriods',
				item.justificationType,
				(e) => service.handleJustifyIncidence([item], e),
				'name',
				service.modalData.justifyTypes,
				'name',
				'Seleccione un justificante'
		  )
		: item.labelText;
}
const renderDropComponent = (
	id,
	value,
	onChange,
	name,
	options,
	optionLabel,
	placeHolder
) => {
	return (
		<div className='flex w-full h-18  flex-col pl-1 pr-1 lg:text-xs'>
			<div className='flex w-full h-full content-center items-center justify-start flex-col'>
				<Dropdown
					value={value}
					onChange={onChange}
					name={name}
					id={id}
					options={options}
					optionLabel={optionLabel}
					className={'!w-full !h-5 !flex  '}
					placeholder={placeHolder}
					disabled={options.length === 0}
				/>
			</div>
		</div>
	);
};

export const createArrayModalJustifyIncidences = (service) => {
	let columns = [
		{
			field: 'code',
			header: TableNames.TableCode,
			flexGrow: 1,
			flexBasis: '10px',
			body: (rowData) => renderBody(rowData?.code),
		},
		{
			field: 'name',
			header: TableNames.TableName,
			flexGrow: 3,
			flexBasis: '50px',
			body: (rowData) => renderBody(rowData?.name),
		},
		{
			field: 'date',
			header: TableNames.TableDate,
			flexGrow: 1,
			flexBasis: '30px',
			body: (rowData) => renderBody(rowData?.date),
		},
		{
			field: 'incidence',
			header: TableNames.TableIncidence,
			flexGrow: 2,
			flexBasis: '20px',
			body: (rowData) => actualIncidenceBody(rowData, service),
		},
		{
			field: 'invitationStatus',
			header: 'Justificante',
			flexGrow: 2,
			flexBasis: '50px',
			body: (rowData) => justifyIncidenceBody(rowData, service),
		},
	];

	if (service.modalData.isIndividual) {
		return columns.filter(
			(item) => item.field !== 'code' && item.field !== 'name'
		);
	}
	return columns;
};

export const processStatus = {
	1: 'Por enviar',
	2: 'Con error',
	3: 'aplicada',
};

export function convertStringToTableData(input) {
	// Separar por " - "
	let [info, message] = input.split(' - ');

	// Extraer fecha y nombre usando una expresión regular
	let match = info.match(/^(\d{2}\/\d{2}\/\d{4}) (.+)$/);

	if (!match) {
		throw new Error('El formato del string no es válido');
	}

	let date = match[1];
	let name = match[2];

	return { date, name, message };
}
