import React, { useState } from 'react';

import { IntegrationComponent } from '../../components/IntegrationComponent';
import { SidebarNames } from '../../namesConstants/names';
import { Events } from './Events';
import { Holidays } from './Holidays';
import { PrenominalPeriods } from './PrenominalPeriods';
import { Schedules } from './Schedules';
import { WorkArea } from './WorkArea';
import { SidebarCatalogsComponent } from '../../components/SidebarCatalogsComponent';

export const Catalogs = () => {
	const [itemSelected, setItemSelected] = useState(1);
	const [currentTap, setCurrentTap] = useState({ id: 1 });

	const [isOpen, setIsOpen] = useState(true);

	const children = [
		{
			name: 'Catálogos',
			icon: 'AiOutlineSchedule',
			children: [
				{
					id: 1,
					url: '/catalogs/workAreas',
					name: 'workAreas',
					title: SidebarNames.SidebarWorkAreas,
					tapTitle: SidebarNames.SidebarWorkAreas,
					icon: 'MdOutlineHomeWork',
					translate: SidebarNames.SidebarWorkAreas,
					type: 'item',
				},
				{
					id: 5,
					url: '/catalogs/holidays',
					name: 'holidays',
					title: SidebarNames.SidebarHolidays,
					tapTitle: SidebarNames.SidebarHolidays,
					icon: 'MdOutlineToday',
					translate: SidebarNames.SidebarHolidays,
					type: 'item',
				},
				{
					id: 2,
					url: '/catalogs/schedules',
					name: 'schedules',
					title: SidebarNames.SidebarSchedules,
					tapTitle: SidebarNames.SidebarSchedules,
					icon: 'MdOutlineAlarm',
					translate: SidebarNames.SidebarSchedules,
					type: 'item',
				},

				{
					id: 3,
					url: '/catalogs/events',
					name: 'events',
					title: SidebarNames.SidebarEvents,
					tapTitle: SidebarNames.SidebarEvents,
					icon: 'MdOutlineDataset',
					translate: SidebarNames.SidebarEvents,
					type: 'item',
				},
			],
		},
	];
	const renderComponent = (valueSelect) => {
		switch (valueSelect) {
			case 1:
				return <WorkArea />;
			case 2:
				return <Schedules />;
			case 3:
				return <Events />;
			case 4:
				return <PrenominalPeriods />;
			case 5:
				return <Holidays />;
			default:
				break;
		}
	};

	return (
		<IntegrationComponent>
			<div className='flex w-full h-full flex-row'>
				<SidebarCatalogsComponent
					isOpen={isOpen}
					setCurrentTap={setCurrentTap}
					currentTap={currentTap}
					setIsOpen={setIsOpen}
					setItemSelected={setItemSelected}
					Data={children}
				/>
				<div className='flex h-full flex-grow'>
					{renderComponent(itemSelected)}
				</div>
			</div>
		</IntegrationComponent>
	);
};
