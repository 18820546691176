import React from 'react';
import { CustomTable } from '../../../components/Table/CustomTable';
import {
	GeneralNames,
	InvitationsScreenNames,
} from '../../../namesConstants/names';
import { headerClassName } from '../../../assets/customStyles';
import { Column } from 'primereact/column';
import { createArrayModalJustifyIncidences } from './utils';
import { PropTypes } from 'prop-types';

export const TableModalComponent = ({ service }) => {
	const columns = createArrayModalJustifyIncidences(service);

	const bodyClassName =
		'!border-r-2 !border-b-0 !border-secondary-PCS004 !text-secondary-PCS001';
	return (
		<CustomTable
			sortField='name'
			value={service.modalData.filterListModal}
			style={{ height: '100%', width: '100%', overflow: 'auto' }}
			responsiveLayout='scroll'
			globalFilterFields={['name', 'code']}
			emptyMessage={GeneralNames.GeneralEmptyMessage}
			currentPageReportTemplate={
				InvitationsScreenNames.CurrentPageReportTemplate
			}
			resizableColumns
			columnResizeMode='fit'
			selection={service.toSendModal}
			selectionMode='checkbox'
			onSelectionChange={(e) => service.setToSendModal(e.value)}
			setSort={service.setSortJustifyTable}
			sort={service.sortJustifyTable}>
			{service.modalData.filterListModal.length > 1 ? (
				<Column
					headerClassName={headerClassName}
					bodyClassName='!text-center !text-secondary-PCS004 !border-r-2 !border-b-0 !border-secondary-PCS004'
					selectionMode='multiple'
					style={{ minWidth: '10px' }}
				/>
			) : null}
			{columns.map(({ field, header, flexGrow, flexBasis, body }) => (
				<Column
					key={field}
					field={field}
					header={header}
					sortable
					headerClassName={headerClassName}
					bodyClassName={bodyClassName}
					style={{ flexGrow, flexBasis }}
					body={body}
				/>
			))}
		</CustomTable>
	);
};

TableModalComponent.propTypes = {
	service: PropTypes.object,
};
