export function formatDateForExcelHeader(date) {
  return new Intl.DateTimeFormat('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(date);
}
export const appName = "CONTPAQi Colabora";

export function sortArray(data, config) {
    const { field, order } = config;
    const orderFactor = order === 1 ? 1 : -1; // Define el orden (ascendente o descendente)

    return [...data].sort((a, b) => {
        const valA = a[field];
        const valB = b[field];

        // Manejo de valores nulos o indefinidos
        if (valA == null || valA === undefined || valA === "") return 1; // `null` y `undefined` van al final
        if (valB == null || valB === undefined || valB === "") return -1;

        // Comparación para fechas
        if (valA instanceof Date && valB instanceof Date) {
            return (valA - valB) * orderFactor;
        }

        // Comparación para cadenas y números
        const blocksA = divideString(String(valA).toLowerCase());
        const blocksB = divideString(String(valB).toLowerCase());

        return compareBlocks(blocksA, blocksB, orderFactor);
    });
}

function divideString(string) {
    // Divide en bloques de letras, números y otros caracteres
    return string.match(/[A-Za-z]+|\d+|[^A-Za-z\d]/g).map(block =>
        isNaN(block) ? block : parseInt(block, 10)
    );
}

function compareBlocks(blocksA, blocksB, orderFactor) {
    const length = Math.min(blocksA.length, blocksB.length);

    for (let i = 0; i < length; i++) {
        const isANumeric = typeof blocksA[i] === "number";
        const isBNumeric = typeof blocksB[i] === "number";

        // Prioriza números sobre letras
        if (isANumeric && !isBNumeric) return -1 * orderFactor;
        if (!isANumeric && isBNumeric) return 1 * orderFactor;

        // Si ambos son del mismo tipo, compara directamente
        if (blocksA[i] < blocksB[i]) return -1 * orderFactor;
        if (blocksA[i] > blocksB[i]) return 1 * orderFactor;
    }

    // Si todos los bloques son iguales, compara por longitud
    return (blocksA.length - blocksB.length) * orderFactor;
}


export function formatDateWithoutTime(dateString) {
  const date = new Date(dateString);
  // Convierte a ISO (ej: "2024-12-01T00:00:00.000Z") y después extrae la parte de la fecha antes de la "T"
  return date.toISOString().split("T")[0];
}

/**
 * Filters an array of objects based on a search text in specific fields.
 *
 * @param {Array<Object>} data - Array of objects to filter.
 * @param {string} searchText - Text to search for.
 * @param {Array<string>} fields - Array of field names to search within.
 * @returns {Array<Object>} Filtered array containing objects that include the search text in at least one specified field.
 */
export function filterObjects(data, searchText, fields) {
    if(searchText === "" || searchText == null || searchText === undefined) return data;
    const lowerSearchText = searchText.toLowerCase();
    return data.filter(item =>
      fields.some(field => {
        if (item[field] !== undefined && item[field] !== null) {
          return item[field].toString().toLowerCase().includes(lowerSearchText);
        }
        return false;
      })
    );
}
function extractColumns(arr) {
    return arr.map(item => {
        let width = 18;
        if(item.field === "code") {
            width = 10
        }
        if(item.field === "name") {
            width = 30
        }
        if(item.field === "department") {
            width = 30
        }
       return {
            title: item.header,
            prop: item.field,
            width
          }
    });
  }
  export function genericExcelTemplate({
    data,
    columns,
    sort,
    fileOptions: { filename },
    reportOptions: { reportName, companyName },
    dateRange: { initialDate, finalDate },
  }) {
    // Modificamos data y extraemos columnas
    const formatedData = sortArray(JSON.parse(JSON.stringify(data)), sort[0]);
    const dynamicColumns = extractColumns(columns);
    const toExcel = {
      filename: filename,
      templateFileName: 'query_template.xlsx',
      replace: [
        {
          type: 0,
          tag: '#simple.name',
          prop: 'name',
          default: 'Simple',
        },
        {
          type: 1,
          tag: '#simple.company',
          prop: 'company',
          default: 'Simple',
        },
        {
          type: 1,
          tag: '#printDate',
          prop: 'printDate',
          default: new Date().toLocaleDateString(),
          format: ['numberFormat', 'dd mmmm yyyy'],
        },
        {
          type: 1,
          tag: '#rangeDate',
          prop: 'rangeDate',
          default: new Date().toLocaleDateString(),
        },
        {
          type: 1,
          tag: '#simple.appName',
          prop: 'appName',
          default: 'Simple',
        },
        {
          type: 1,
          tag: '#simple.name',
          prop: 'name',
          default: 'Simple',
        },
        {
          type: 2,
          tag: '#simple.cols',
          prop: 'cols',
        },
        {
          type: 3,
          tag: '#simple.rows',
          prop: 'rows',
          colprop: 'cols',
        },
      ],
      data: {
        company: companyName,
        autoFilter: true,
        gridLinesVisible: true,
        printDate: formatDateForExcelHeader(new Date()),
        rangeDate: `${formatDateWithoutTime(initialDate)} - ${formatDateWithoutTime(finalDate)}`,
        appName: appName,
        name: reportName,
        cols: dynamicColumns,
        rows: formatedData
      },
    };
    return toExcel;
  }
  