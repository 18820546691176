export const GeneralNames = {
	GeneralALl: 'Todos',
	GeneralGuests: 'Invitados',
	GeneralToInvite: 'Por Enviar',
	GeneralStatusSend: 'Enviada',
	GeneralStatusSends: 'Enviadas',
	GeneralStatusPending: 'Pendiente',
	GeneralStatusAccepted: 'Aceptada',
	GeneralStatusRejected: 'Rechazada',
	GeneralStatusApplied: 'Aplicada',
	GeneralStatusUnavailable: 'No disponible',
	GeneralEmptyMessage: 'No hay datos para mostrar',
	GeneralStartDate: 'Fecha de Inicio',
	GeneralEndDate: 'Fecha de Fin',
	GeneralExportToExcel: 'Exportar a EXCEL',
	GeneralReloadInformation: 'Recargar Información',
	GeneralCleanFilters: 'Limpiar Filtros',
	GeneralAcceptChanges: 'Aceptar Cambios',
	GeneralRejectChanges: 'Rechazar Cambios',
	GeneralEmptyOptions: 'No hay opciones',
	GeneralSend: 'Enviar',
	GeneralAccept: 'Aceptar',
	GeneralReject: 'Rechazar',
	GeneralAnnular: 'Anular',
	GeneralCancel: 'Cancelar',
	GeneralSave: 'Guardar',
	GeneralToDefine: 'Por Definir',
	GeneralMyProfile: 'Mi Cuenta',
	GeneralTermsAndConditions: 'Licencia de Uso',
	GeneralPrivacyPolicy: 'Aviso de Privacidad',
	GeneralVersion: 'Acerca de',
	GeneralLogout: 'Cerrar Sesión',
	GeneralStatusExpired: 'Expirada',
	GeneralStatusExpiredPlural: 'Expiradas',
	GeneralFilter: 'Restaurar filtros',
	EmptyDataMessageExport: 'No hay datos para exportar',
	EmptyDataMessageBodyExport: 'Se necesita al menos un registro para exportar',
	EmptyDataCollaborator: 'Debe seleccionar al menos un colaborador.',
	GeneralStatusError: 'Error',
	GeneralNameComingSoon: ' PRÓXIMAMENTE',
	GeneralNamesSearchGeneral: 'Búsqueda General',
	GeneralNamesSearchByUser: 'Búsqueda por usuario',
	GeneralContinue: 'Continuar',
	GeneralAdd: 'Agregar',
	Active: 'Alta',
	Inactive: 'Baja',
	GeneralAssign: 'Asignar',
};

export const TableNames = {
	TableFirstName: 'Nombre',
	TableLastName: 'Apellido',
	TableEmail: 'Correo Electrónico',
	TablePhone: 'Teléfono',
	TableStatus: 'Estado',
	TableActions: 'Acciones',
	TableCode: 'Código',
	TableDateToInvite: 'Fecha de Invitación',
	TableRFC: 'RFC',
	TableEntryDate: 'Fecha de Entrada',
	TableDataToBeChanged: 'Datos a Cambiar',
	TableNewData: 'Nuevo Dato',
	TablePreviousValue: 'Valor Anterior',
	TableReEntryDate: 'Fecha de Reingreso',
	TableNewValue: 'Nuevo Valor',
	TableDepartment: 'Departamento',
	TableDateToResponse: 'Fecha de Respuesta',
	TableStatusInvitation: 'Estado de Invitación',
	TableStatusName: 'Estatus',
	TableDateToChange: 'Fecha de Cambio',
	TableDateOfApplication: 'Fecha de Solicitud',
	TableAdditionalInformation: 'Información Adicional',
	TableDateOfStatus: 'Fecha de Estatus',
	TableDateOfState: 'Fecha de Estado',
	TableDescription: 'Descripción',
	TableName: 'Nombre',
	TableAddress: 'Dirección',
	TableStatusWorkArea: 'Estatus',
	TableDateIncident: 'Fecha de Incidencia',
	TableShift: 'Turno',
	TableIncidentType: 'Tipo de incidencia',
	TableErrorName: 'Error',
	TableType: 'Tipo',
	TableLeader: 'Líder',
	TableState: 'Estado',
	TableDuration: 'Duración',
	TablePeriod: 'Periodo',
	TablePosition: 'Puesto',
	TableActualLeader: 'Líder actual',
	TableCompanyName: 'Empresa',
	TableValue: 'Valor',
	TableDate: 'Fecha',
	TableActualIncidence: 'Incidencia actual',
	TableIncidence: 'Incidencia',
	TableJustify: 'Justificante',
};

export const SidebarNames = {
	SidebarInvitations: 'Invitaciones',
	SidebarAssist: 'Asistencias',
	SidebarApplications: 'Solicitudes',
	SidebarVacations: 'Vacaciones',
	SidebarAbsences: 'Ausencias',
	SidebarCollaborator: 'Colaboradores',
	SidebarCatalogs: 'Catálogos',
	SidebarWorkAreas: 'Centros de trabajo',
	SidebarSchedules: 'Horarios y descansos',
	SidebarEvents: 'Tipos de incidencias',
	SidebarPrenominalPeriods: 'Periodos de incidencias',
	SidebarHolidays: 'Días no laborables',
	SidebarIncidences: 'Envío de Incidencias',
	SidebarConfiguration: 'Configuración',
	SidebarOperation: 'Colaboradores',
	SidebarNameAssist: 'Asistencia',
	ParametersOfAssistance: 'Parámetros de asistencia',
	SidebarQueries: 'Consultas',
	OrganizationChart: 'Organigrama',
	JustifyIncidences: 'Justificar Incidencias',
};

export const InvitationsScreenNames = {
	InvitationsScreenTitle: 'Invitaciones',
	AlertValidationEmptyArrayTitle: 'No se ha seleccionado ninguna invitación',
	AlertValidationEmptyArraySubTitle:
		'Por favor, seleccione al menos una invitación',
	PlaceHolderDropdown: 'Buscar Invitaciones',
	TextIconAccept: 'Enviar Invitaciones',
	TextUpdateInvitations: 'Actualizar Invitaciones',
	TextTooltip: 'Invitar Colaborador',
	TextTooltipUnInvite: 'Anular Invitación',
	CurrentPageReportTemplate:
		'Mostrando {first} a {last} de {totalRecords} invitaciones',
	CustomAlertDialogTittleInvite: '¿Está seguro que desea enviar la invitación?',
	CustomAlertDialogTittleUnInvite:
		'¿Está seguro que desea anular la invitación seleccionada?',
	CustomAlertDialogInviteMessage:
		'Se enviará una invitación para que los colaboradores seleccionados puedan acceder a la aplicación móvil CONTPAQi® Colabora',
	CustomAlertDialogUnInvitedMessage:
		'Se enviará la solicitud para que se anule la invitación para los colaboradores seleccionados',
	CustomAlertDialogSuccessMessage: 'Invitaciones enviadas correctamente',
};

export const CollaboratorScreenNames = {
	CollaboratorScreenTitle: 'Colaboradores',
	PlaceHolderDropdown: 'Buscar Colaboradores',
	TextIconAccept: 'Aceptar cambios seleccionados',
	TextTooltip: 'Aceptar cambio de información',
	TextTooltipReject: 'Rechazar cambio de información',
	CurrentPageReportTemplate:
		'Mostrando {first} a {last} de {totalRecords} colaboradores',
	CustomAlertDialogTittleAccept: '¿Está seguro que desea aceptar los cambios?',
	CustomAlertDialogTittleReject: '¿Está seguro que desea rechazar los cambios?',
	CustomAlertDialogMessage: 'Se aceptarán los cambios seleccionados',
	CustomAlertDialogSuccessMessageAccept: 'Los cambios serán aprobados',
	CustomAlertDialogSuccessMessageReject: 'Los cambios serán rechazados',
	AlertValidationEmptyArrayTitle: 'No se ha seleccionado ningún colaborador',
	AlertValidationEmptyArraySubTitle:
		'Por favor, seleccione al menos un colaborador',
	CustomAlertOpenWorkArea:
		'¿Estás seguro de que deseas {openWorkArea} la validación de centros y lugares de trabajo para {fullname}?',
};

export const AlertToastInfoNames = {
	SendInvitationSuccess: 'Invitación enviada con éxito',
	sendInvitationWarning: 'Ocurrió un error al enviar algunas invitaciones',
	sendUnInvitationSuccess: 'Invitación cancelada con éxito',
	sendUnInvitationWarning:
		'Ocurrió un problema al anular algunas invitaciones. Asegúrate de que los colaboradores hayan sido previamente invitados',
	CancelInvitationSuccess: 'Invitación cancelada con éxito',
	ReImportDataSuccess: 'Datos importados con éxito',
	MaxLicenseAvailable: 'Límite de licencia de contratos',
	AlertLicenseAvailable: `No es posible invitar a más colaboradores debido a que se llegó al límite de contratados,
		puede des-invitar a colaboradores o contactar a su distribuidor para aumentar la licencia`,
	SendAttendanceSuccess:
		'Configuración de asistencia laboral enviada con éxito',
	SendIncidenteSuccess: 'Configuración de incidencia enviada con éxito',
	SendNotificationSuccess: 'Configuración de notificación enviada con éxito',
};

export const WorkAreaNames = {
	CurrentPageReportTemplate:
		'Mostrando {first} a {last} de {totalRecords} áreas de trabajo',
	WorkAreaScreenTitle: 'Centros de Trabajo',
	PlaceHolderDropdown: 'Buscar Centros de Trabajo',
};
export const WorkAreaCollaboratorNames = {
	FindCollaborator: 'Buscar Colaboradores',
};

export const workCenterScreenNames = {
	CustomAlertDialogTittleReject:
		'¿Está seguro que desea eliminar el centro de trabajo?',
	CustomAlertDialogSuccessMessageDelete: 'El centro de trabajo será eliminado',
};

export const dayOffScreenNames = {
	CustomAlertDialogTittleReject:
		'¿Está seguro que desea eliminar el día no laborado?',
	CustomAlertDialogSuccessMessageDelete:
		'El día no laborado será eliminado del calendario.',
};

export const HolidaysScreenNames = {
	HolidaysScreenTitle: 'Días Festivos',
	PlaceHolderDropdown: 'Buscar Días Festivos',
	TextIconAccept: 'Aceptar cambios seleccionados',
	TextTooltip: 'Aceptar cambio de información',
	TextTooltipReject: 'Rechazar cambio de información',
	LegalHolidayNames: 'Festivos de ley',
};
export const ScheduleNames = {
	CurrentPageReportTemplate:
		'Mostrando {first} a {last} de {totalRecords} horarios',
	PlaceHolderSearch: 'Búsqueda General',
	ScreenTitle: 'Horarios',
};

export const OperationNames = {
	CurrentPageReportTemplate:
		'Mostrando {first} a {last} de {totalRecords} empleados',
	PlaceHolderSearch: 'Búsqueda General',
	ScreenTitle: 'Operaciones',
	MttoCollaborators: 'Mantenimiento de Colaboradores',
	Collaborators: 'Colaboradores',
	RegisterCheck: 'Registro de Checadas',
	CustomAlertDialogSuccessMessageDeleteSchedule:
		'El horario programado será eliminado',
	CustomAlertDialogTittleDeleteSchedule:
		'¿Está seguro que desea eliminar el horario programado?',
	AllCollaborators: 'Todos los Colaboradores',
	InvitationAcceptedCollaborators: 'Colaboradores con invitación aceptada',
	ParametersOfConsultation: 'Parámetros de consulta',
};

export const TypesIncidencesNames = {
	CurrentPageReportTemplate:
		'Mostrando {first} a {last} de {totalRecords} tipos de incidencias',
};

export const AlertChangeData = {
	CustomAlertDialogTittle: 'Cambios detectados en {view}',
	CustomAlertDialogMessage:
		'Si continua perderá los cambios, para conservar los cambios dé clic en cancelar y guarde cambios.',
};

export const OrganizationChartNames = {
	CurrentPageReportTemplate:
		'Mostrando {first} a {last} de {totalRecords} del listado',
	TextTooltip: 'Asignar lideres.',
	ParametersOfConsultation: 'Parámetros de consulta',
	AllCollaborators: 'Todos los Colaboradores',
	NoInvitation: 'Colaboradores sin invitación',
	InvitationAcceptedCollaborators: 'Colaboradores con invitación aceptada',
	ModalErrors: 'Error al asignar Lideres',
	DeleteLeaderModalTitle:
		'¿Está seguro que desea eliminar la asignación del líder?',
};

export const ErrorsNames = {
	CurrentPageReportTemplate:
		'Mostrando {first} a {last} de {totalRecords} de errores.',
};
