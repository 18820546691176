import React, { useContext } from 'react';
import { Dialog } from 'primereact/dialog';
import { TableModalComponent } from './TableModalComponent';
import { MultiSelectComponent } from '../../../components/MultiSelect/MultiSelectComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FullLoading } from '../../../components/FullLoading';
import { PropTypes } from 'prop-types';
import { StoreContext } from '../../../business/Provider';

export const ModalComponent = ({ service }) => {
	const { loadingState } = useContext(StoreContext);
	const renderTable = () => {
		return <TableModalComponent service={service} />;
	};

	const renderMultiSelect = (
		id,
		placeholder,
		optionLabel,
		value,
		options,
		onChange,
		otherPlaceHolder
	) => {
		return (
			<MultiSelectComponent
				id={id}
				value={value}
				optionLabel={optionLabel}
				options={options}
				onChange={onChange}
				placeholder={placeholder}
				otherPlaceHolder={otherPlaceHolder}
			/>
		);
	};

	const renderDropComponent = (
		id,
		value,
		onChange,
		name,
		options,
		optionLabel,
		placeHolder
	) => {
		return (
			<div className='flex w-full h-18  flex-col pl-1 pr-1 lg:text-xs'>
				<div className='flex w-full h-full content-center items-center justify-start flex-col'>
					<Dropdown
						value={value}
						onChange={onChange}
						name={name}
						id={id}
						options={options}
						optionLabel={optionLabel}
						className={'!w-full !h-5 !flex  '}
						placeholder={placeHolder}
						disabled={options.length === 0}
					/>
				</div>
			</div>
		);
	};

	const content = () => {
		return (
			<div className='flex flex-col w-full h-full space-y-2'>
				<div className='flex w-full h-12 flex-row justify-start items-center'>
					{!service.modalData.isIndividual ? (
						<div className='flex h-full w-1/3 p-1'>
							{renderMultiSelect(
								'collaboratorsModal',
								'Seleccione Colaboradores',
								'name',
								service.modalData.collaboratorsSelect,
								service.modalData.optionsCollaboratorsDrop,
								service.onHandleCollaboratorsSelection,

								'Todos los colaboradores'
							)}
						</div>
					) : null}
					<div
						className={`flex ${
							service.modalData.isIndividual ? 'w-2/3' : 'w-1/3'
						}`}>
						<p className='ml-2 text-md font-bold'>
							{'Colaboradores seleccionados: '}
						</p>
						{!service.modalData.isIndividual ? (
							<button className='flex h-4 w-32 flex-row bg-slate-300 text-black rounded-md justify-center items-center content-center ml-2'>
								<FontAwesomeIcon
									size='sm'
									className='!text-black flex !text-sm justify-center content-center items-center '
									icon={faPerson}
									title={
										service.modalData.collaborators.length + ' Colaboradores'
									}
								/>
								<p className='text-xs font-bold ml-1'>
									{`${service.modalData.collaboratorsCount} Colaborador${
										service.modalData.collaboratorsCount === 1 ? '' : 'es'
									}`}
								</p>
							</button>
						) : (
							<p className='ml-2'>
								{service.modalData.collaborators[0].code +
									' - ' +
									service.modalData.collaborators[0].name}
							</p>
						)}
					</div>

					<div className='flex w-1/3 pr-4'>
						{service.modalData.onJustify &&
						service.modalData.filterListModal.length > 1
							? renderDropComponent(
									'typePeriods',
									null,
									(e) => service.handleJustifyIncidence(service.toSendModal, e),
									'name',
									service.modalData.justifyTypes,
									'name',
									'Seleccione un justificante'
							  )
							: null}
					</div>
				</div>
				{renderTable()}
				<div className='flex w-full h-6  flex-row p-1 justify-end'>
					<Button
						label={service.modalData.onJustify ? 'Aplicar' : 'Deshacer'}
						className='!flex !w-28 !h-full'
						aria-label='Submit'
						type='submit'
						onClick={() => service.onSubmitForm()}
					/>
				</div>
			</div>
		);
	};

	return (
		<Dialog
			header={
				service.modalData.onJustify
					? 'Justificación de incidencias'
					: 'Deshacer Justificantes'
			}
			visible={service.modalData.visible}
			modal
			headerClassName='w-full bg-primary-PCS004 text-white !h-14'
			style={{ width: '70vw', position: 'relative', height: '70vh' }}
			onHide={() => service.onCloseModal()}>
			<div className='flex w-full h-full flex-col relative'>
				<FullLoading loadingState={loadingState} />
				{content()}
			</div>
		</Dialog>
	);
};

ModalComponent.propTypes = {
	service: PropTypes.object,
};
