import { createContext, useContext, useEffect, useState } from 'react';
import { useReportCatalog } from '../../../hooks/reports/useReportCatalogs';
import useReload from '../../../hooks/useReload';
import { StoreContext } from '../../../business/Provider';

import {
	downloadFileBase64,
	generateFilteredData,
	getFileNameWithDateTime,
	incidencesToJustify,
} from '../../../utils/utils';
import moment from 'moment';
import { Toast } from '../../../components/toast';
import { FilterMatchMode } from 'primereact/api';
import {
	DynamicColumnsJustifyIncidences,
	enumsTypesIncidences,
	processIncidencesToTable,
	processUndoIncidencesToTable,
	convertStringToTableData,
} from '../../../screens/justifyIncidence/components/utils';
import { useJustifyIncidences } from '../../../hooks/justifyIncidences/useJustifyIncidences';
import { GeneralNames, TableNames } from '../../../namesConstants/names';
import { LOADING_OFF, LOADING_ON } from '../../../business/constants';
import { filterObjects, genericExcelTemplate } from '../../../utils/excelUtils';

export const JustifyIncidenceContextManager = createContext();

const today = new Date();
today.setDate(today.getDate() - 30);
const initialValuesFilter = {
	typeQuery: {
		name: 'Incidencias',
		code: 'I',
	},
	startDate: today,
	endDate: new Date(),
	typePeriods: [],
	workCenters: [],
	shifts: [],
	departments: [],
	positions: [],
	collaborators: [],
	typeIncidents: [],
	inactivesToo: false,
};

const initModalData = {
	visible: false,
	isIndividual: false,
	collaborators: [],
	dataModalTable: [],
	collaboratorsSelect: [],
	filterListModal: [],
	justifyTypes: [],
	onJustify: true,
	collaboratorsCount: 0,
	optionsCollaboratorsDrop: [],
	onClickDate: false,
};
const initTableErrorsData = {
	openModalErrors: false,
	columnsTable: [],
	dataTable: [],
};

export const JustifyIncidenceProvider = ({ children }) => {
	const { getCatalogs } = useReportCatalog();
	const { getJustifyIncidence, postJustifyIncidences, undoJustifyIncidences } =
		useJustifyIncidences();
	const [filterData, setFilterData] = useState(initialValuesFilter);
	const [forceUpdateKey, setForceUpdateKey] = useState(0);
	const [forceUpdateKeyTwo, setForceUpdateKeyTwo] = useState(0);
	const [dynamicColumns, setDynamicColumns] = useState([]);
	const [sort, setSort] = useState([{ field: 'code', order: 1 }]);
	const [toSend, setToSend] = useState([]);
	const [sortJustifyTable, setSortJustifyTable] = useState([
		{ field: 'code', order: -1 },
	]);
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [globalFilterValue, setGlobalFilterValue] = useState('');

	const [catalogData, setCatalogData] = useState({
		periodType: [],
		employee: [],
		position: [],
		workCenter: [],
		shift: [],
		department: [],
		incidentType: [],
	});

	const [toSendModal, setToSendModal] = useState([]);
	const [tableDataQuery, setTableDataQuery] = useState([]);
	const [tableDataQueryFilterList, setTableDataQueryFilterList] = useState([]);
	const [modalData, setModalData] = useState(initModalData);
	useReload([getCatalogs, getReloadData]);
	const [typeIncidents, setTypeIncidents] = useState([]);
	const [dataTableModalErrors, setDataTableModalErrors] =
		useState(initTableErrorsData);

	const {
		getCatalogReportState,
		getJustifyIncidencesContextState,
		getJustifyIncidencesTypesContextState,
		contextLoading,
		dispatchLoading,
		companyState,
		postJustifyIncidencesContextState,
		postUndoJustifyIncidencesContextState,
	} = useContext(StoreContext);

	const { stateGetJustifyIncidences } = getJustifyIncidencesContextState;
	const { stateJustifyIncidencesTypes } = getJustifyIncidencesTypesContextState;
	const { postJustifyIncidencesClear_DP, statePostJustifyIncidences } =
		postJustifyIncidencesContextState;

	const { statePostUndoJustifyIncidences, postUndoJustifyIncidencesClear_DP } =
		postUndoJustifyIncidencesContextState;

	useEffect(() => {
		if (statePostJustifyIncidences.error !== null) {
			const _dataModal = statePostJustifyIncidences.error.map((item) => {
				return convertStringToTableData(item);
			});
			const columns = [
				{
					field: 'date',
					header: TableNames.TableDate,
					style: { flexGrow: 1, flexBasis: '100px' },
				},
				{
					field: 'name',
					header: TableNames.TableName,
					style: { flexGrow: 2, flexBasis: '100px' },
				},
				{
					field: 'message',
					header: TableNames.TableErrorName,
					style: { flexGrow: 3, flexBasis: '100px' },
				},
			];

			setDataTableModalErrors({
				openModalErrors: true,
				columnsTable: columns,
				dataTable: _dataModal,
			});
		}
	}, [statePostJustifyIncidences]);

	useEffect(() => {
		if (statePostUndoJustifyIncidences.error !== null) {
			const _dataModal = statePostUndoJustifyIncidences.error.map((item) => {
				return convertStringToTableData(item);
			});
			const columns = [
				{
					field: 'date',
					header: TableNames.TableDate,
					style: { flexGrow: 1, flexBasis: '100px' },
				},
				{
					field: 'name',
					header: TableNames.TableName,
					style: { flexGrow: 2, flexBasis: '100px' },
				},
				{
					field: 'message',
					header: TableNames.TableErrorName,
					style: { flexGrow: 3, flexBasis: '100px' },
				},
			];

			setDataTableModalErrors({
				openModalErrors: true,
				columnsTable: columns,
				dataTable: _dataModal,
			});
		}
	}, [statePostUndoJustifyIncidences]);

	useEffect(() => {
		if (getCatalogReportState.catalogs !== null) {
			const { data } = getCatalogReportState.catalogs;

			setCatalogData(data);
		}
	}, [getCatalogReportState]);

	useEffect(() => {
		if (modalData.collaboratorsSelect.length > 0 && modalData.visible) {
			const filterCollaborators = modalData.dataModalTable.filter((element) =>
				modalData.collaboratorsSelect.some(
					(item) => item.id === element.collaboratorCode
				)
			);

			setModalData({
				...modalData,
				filterListModal: filterCollaborators,
				collaboratorsCount: modalData.collaboratorsSelect.length,
			});
		} else {
			setModalData({
				...modalData,
				filterListModal: [],
				collaboratorsCount: 0,
			});
		}
	}, [modalData.collaboratorsSelect, modalData.dataModalTable]);

	useEffect(() => {
		if (stateJustifyIncidencesTypes.data !== null) {
			setModalData({
				...modalData,
				justifyTypes: stateJustifyIncidencesTypes.data,
			});
		}
	}, [stateJustifyIncidencesTypes]);

	useEffect(() => {
		if (stateGetJustifyIncidences.data !== null) {
			setTableDataQuery(stateGetJustifyIncidences.data);
			setTableDataQueryFilterList(stateGetJustifyIncidences.data);
		}
	}, [stateGetJustifyIncidences]);

	useEffect(() => {
		const columns = DynamicColumnsJustifyIncidences(
			filterData.startDate,
			filterData.endDate
		);

		setDynamicColumns(columns);
	}, []);

	useEffect(() => {
		if (typeIncidents.length > 0) {
			// Extrae los valores ID del array de incidentes y los convierte a números enteros
			const valuesIds = typeIncidents.map((item) => parseInt(item.id, 10));

			// Filtra los datos de la tabla
			const filteredData = tableDataQuery.filter((item) => {
				return Object.entries(item.incidences || {}).some(([date, value]) => {
					if (value !== null) {
						const splitValue = value.split(' | ');
						const incidentValue = parseInt(splitValue[1], 10); // Convierte a número entero

						return valuesIds.includes(incidentValue); // Compara con valuesIds
					}
					return false;
				});
			});

			setTableDataQueryFilterList(filteredData);
		} else {
			setTableDataQueryFilterList(tableDataQuery);
		}
	}, [typeIncidents, tableDataQuery]);

	function handleStartAndEndDate(event) {
		const { name, value } = event.target;

		// Update the respective key based on the name of the field
		if (name === 'startDate') {
			setForceUpdateKey((prevKey) => prevKey + 1);
		} else if (name === 'endDate') {
			setForceUpdateKeyTwo((prevKey) => prevKey + 1);
		}

		// Update the filter data
		setFilterData((prevFilterData) => ({
			...prevFilterData,
			[name]: value,
		}));
	}

	function handleSetFilterData(e) {
		const { name, value, checked, type } = e.target;
		setFilterData({
			...filterData,
			[name]: type === 'checkbox' ? checked : value,
		});
	}

	function onCancelJustifyIncidence(rowData) {
		const updateData = modalData.dataModalTable.map((obj) =>
			obj.collaboratorCode === rowData.collaboratorCode
				? { ...obj, justification: false, justificationType: '' }
				: obj
		);

		setModalData({
			...modalData,
			dataModalTable: updateData,
		});
	}

	function clearTextLabel() {
		setGlobalFilterValue('');
		setFilters({
			global: { value: '', matchMode: FilterMatchMode.CONTAINS },
		});
	}

	function onGlobalFilterChange(e) {
		const { value } = e.target;
		const _filters = { ...filters };
		_filters.global.value = value;
		setFilters(_filters);
		setGlobalFilterValue(value);
	}

	/**
	 * Updates the modal data table by justifying a list of incidents.
	 *
	 * @param {Array} rowDataArray - Array of incident objects to justify.
	 * @param {Object} type - Object containing the justification type value.
	 */
	function handleJustifyIncidence(_justifyIncidencesList, type) {
		const updateData = modalData.dataModalTable.map((obj) => {
			return _justifyIncidencesList.some(
				(row) => row.incidentId === obj.incidentId
			)
				? {
						...obj,
						justification: true,
						justificationType: type.value,
				  }
				: obj;
		});

		const updateToSend = toSendModal.map((_item) => {
			return _justifyIncidencesList.some(
				(row) => row.incidentId === _item.incidentId
			)
				? {
						..._item,
						justification: true,
						justificationType: type.value,
				  }
				: _item;
		});

		setToSendModal([...updateToSend]);

		setModalData({
			...modalData,
			dataModalTable: updateData,
		});
	}

	function handleSetIncidences(e) {
		const values = e.target.value;
		setTypeIncidents(values);
	}

	async function getReloadData() {
		return await getDataQueries();
	}

	function handleSelectionTable(data, isIndividual, date = '') {
		const transformData = processIncidencesToTable(data, date);

		if (transformData.length > 0) {
			const _optionsCollaboratorsDrop = tableDataQueryFilterList.map((item) => {
				return { id: item.employeeId, name: item.name };
			});

			const _newOptionsCollaboratorsDrop = _optionsCollaboratorsDrop.filter(
				(ob) => transformData.some((it) => it.collaboratorCode === ob.id)
			);

			setModalData({
				...modalData,
				visible: true,
				isIndividual: transformData.length <= 1 ? true : isIndividual,
				collaborators: data,
				dataModalTable: transformData,
				filterListModal: transformData,
				justifyTypes: stateJustifyIncidencesTypes.data,
				collaboratorsSelect: _newOptionsCollaboratorsDrop,
				collaboratorsCount: _newOptionsCollaboratorsDrop.length,
				optionsCollaboratorsDrop: _newOptionsCollaboratorsDrop,
			});
		} else {
			const text = isIndividual
				? 'El Colaborador no cuenta con incidencias para justificar'
				: 'No se encontraron incidencias para justificar.';

			Toast('info', text);
		}
	}
	function undoJustification(data, isIndividual, date = '') {
		const transformData = processUndoIncidencesToTable(data, date);

		if (transformData.length > 0) {
			const _optionsCollaboratorsDrop = tableDataQueryFilterList.map((item) => {
				return { id: item.employeeId, name: item.name };
			});

			const _newOptionsCollaboratorsDrop = _optionsCollaboratorsDrop.filter(
				(ob) => transformData.some((it) => it.collaboratorCode === ob.id)
			);

			setModalData({
				...modalData,
				visible: true,
				isIndividual: transformData.length <= 1,
				onJustify: false,
				collaborators: data,
				dataModalTable: transformData,
				filterListModal: transformData,
				justifyTypes: stateJustifyIncidencesTypes.data,
				collaboratorsSelect: _newOptionsCollaboratorsDrop,
				collaboratorsCount: _newOptionsCollaboratorsDrop.length,
				optionsCollaboratorsDrop: _newOptionsCollaboratorsDrop,
			});
		} else {
			Toast('info', 'No hay justificantes para deshacer.');
		}
	}

	function onClickDate(date, data, isJustify) {
		const transformData =
			isJustify !== '7'
				? processIncidencesToTable([data], date)
				: processUndoIncidencesToTable([data], date);

		if (transformData.length > 0) {
			const { incidenceType } = transformData[0];

			if (!['3', '5', '7'].includes(incidenceType)) {
				return Toast(
					'info',
					'No hay incidencias para justificar en el día seleccionado.'
				);
			}

			const _optionsCollaboratorsDrop = tableDataQueryFilterList.map((item) => {
				return { id: item.employeeId, name: item.name };
			});

			const _newOptionsCollaboratorsDrop = _optionsCollaboratorsDrop.filter(
				(ob) => transformData.some((it) => it.collaboratorCode === ob.id)
			);

			setModalData({
				...modalData,
				visible: true,
				isIndividual: true,
				collaborators: transformData,
				dataModalTable: transformData,
				filterListModal: transformData,
				justifyTypes: stateJustifyIncidencesTypes.data,
				collaboratorsSelect: _newOptionsCollaboratorsDrop,
				collaboratorsCount: _newOptionsCollaboratorsDrop.length,
				optionsCollaboratorsDrop: _newOptionsCollaboratorsDrop,
				onJustify: isJustify !== '7',
				onClickDate: true,
			});
		} else {
			Toast(
				'info',
				'No se encontraron incidencias para justificar en la fecha seleccionada.'
			);
		}
	}

	function onCloseModal() {
		setModalData(initModalData);
		setToSendModal([]);
	}

	function onHandleCollaboratorsSelection(e) {
		const { value } = e.target;
		setModalData({
			...modalData,
			collaboratorsSelect: value,
			collaboratorsCount: value.length,
		});
	}

	const resetSeconds = (dateString) => {
		return moment(dateString).seconds(0).milliseconds(0).toISOString(); // Ajusta segundos y milisegundos a cero
	};

	function onCloseModalErrors() {
		setDataTableModalErrors(initTableErrorsData);
		postJustifyIncidencesClear_DP();
		postUndoJustifyIncidencesClear_DP();
	}

	function generateDataToSend() {
		return {
			startDate: resetSeconds(filterData.startDate),
			endDate: resetSeconds(filterData.endDate),
			typePeriods: generateFilteredData(
				catalogData.periodType,
				filterData.typePeriods
			),
			workCenters: generateFilteredData(
				catalogData.workCenter,
				filterData.workCenters
			),
			workShifts: generateFilteredData(catalogData.shift, filterData.shifts),
			departments: generateFilteredData(
				catalogData.department,
				filterData.departments
			),
			jobsPosition: generateFilteredData(
				catalogData.position,
				filterData.positions
			),
			collaborators: generateFilteredData(
				catalogData.employee,
				filterData.collaborators
			),
			typeIncidents: generateFilteredData(
				incidencesToJustify,
				filterData.typeIncidents
			),
		};
	}

	async function getDataQueries() {
		const newEndDate = moment(filterData.endDate);
		const newStartDate = moment(filterData.startDate);
		const daysDiff = newEndDate.diff(newStartDate, 'days');
		const maxDate = newStartDate.clone().add(30, 'days');

		// Validaciones de rango de fechas
		if (newEndDate.isBefore(newStartDate)) {
			Toast(
				'warning',
				'La fecha de finalización no puede ser menor que la fecha de inicio.'
			);
			setFilterData((prev) => ({
				...prev,
				endDate: maxDate.toDate(),
			}));
			return;
		}

		if (daysDiff > 30) {
			Toast(
				'warning',
				'La diferencia entre las fechas no puede ser mayor de 30 días.'
			);
			setFilterData((prev) => ({
				...prev,
				endDate: maxDate.toDate(),
			}));
			return;
		}

		const columns = DynamicColumnsJustifyIncidences(
			filterData.startDate,
			filterData.endDate
		);

		setDynamicColumns(columns);
		setFilterData({
			...filterData,
		});
		const dataToSend = generateDataToSend();

		return await getJustifyIncidence(dataToSend);
	}

	/**
	 * Creates an array of justified incidence data to send.
	 *
	 * @param {Array} modalData - Array of incidence objects.
	 * @returns {Array} Filtered and formatted incidence data.
	 */
	function createSendDataIncidences(modalData) {
		const _modalData = modalData
			.filter((item) => item.justification)
			.map((item) => {
				return {
					attendanceId: item.attendanceId,
					incidentTypeId: item.justificationType.incidentTypeId, // Corregido: antes estaba asignado a attendanceId
					employeeId: item.collaboratorCode,
					colaboraInstanceId: item.colaboraInstanceId,
				};
			});

		return _modalData;
	}
	function createSendDataUndo(modalData) {
		return modalData.map((item) => {
			return {
				attendanceId: item.attendanceId,
				incidentTypeId:
					item.justificationType !== null
						? item.justificationType?.incidentTypeId
						: null, // Corregido: antes estaba asignado a attendanceId
				employeeId: item.collaboratorCode,
				colaboraInstanceId: item.colaboraInstanceId,
			};
		});
	}
	function onSubmitForm() {
		const { onClickDate, onJustify, filterListModal, isIndividual } = modalData;

		// Determinar los datos seleccionados
		let selectedData = filterListModal;
		if (!onClickDate && !isIndividual) {
			selectedData = toSendModal;
		}

		// Generar datos a enviar
		const dataToSend = onJustify
			? createSendDataIncidences(selectedData)
			: createSendDataUndo(selectedData);

		// Validar que haya datos a enviar
		if (!onClickDate && dataToSend.length === 0) {
			return Toast(
				'info',
				'Seleccione los justificantes que desea deshacer para continuar'
			);
		}
		if (dataToSend.length === 0) {
			return Toast(
				'info',
				onJustify
					? 'No hay datos para justificar'
					: 'No hay datos para deshacer la justificación.'
			);
		}

		// Generar filtro de datos a enviar
		const dataToSendFilter = generateDataToSend();

		// Seleccionar la función de procesamiento
		const processFunction = onJustify
			? postJustifyIncidences
			: undoJustifyIncidences;

		// Ejecutar la función y cerrar el modal al finalizar
		return processFunction(dataToSend, dataToSendFilter).finally(onCloseModal);
	}

	function clearFilters() {
		setFilterData(initialValuesFilter);
	}
	function splitDates(arr) {
		return arr.map((obj) => {
			for (let key in obj) {
				if (['colaboraInstanceId', 'employeeId', 'incidences'].includes(key)) {
					delete obj[key];
					continue;
				}
				const isValid = moment(key, 'DD/MM/YYYY', true).isValid();
				if (isValid) {
					if (obj[key] == null) {
						obj[key] = 'N/D';
						continue;
					}
					let splitRowData = obj[key].split('|');
					const labelText = splitRowData[4]?.trim() || 'N/D';
					obj[key] = labelText;
				}
			}
			return obj;
		});
	}
	async function toExcelExport() {
		const deepCopy = JSON.parse(JSON.stringify(tableDataQueryFilterList));
		const filtered = filterObjects(deepCopy, globalFilterValue, [
			'code',
			'name',
			'department',
		]);
		if (filtered.length > 0) {
			const toExcel = genericExcelTemplate({
				data: splitDates(filtered),
				columns: dynamicColumns,
				sort: sort,
				fileOptions: { filename: 'JustificacionIncidencias.xlsx' },
				reportOptions: {
					reportName: 'Justificación de Incidencias',
					companyName: companyState?.companies?.name,
				},
				dateRange: {
					initialDate: filterData.startDate,
					finalDate: filterData.endDate,
				},
			});
			dispatchLoading({ type: LOADING_ON });

			const filName = getFileNameWithDateTime('Justificacion_Incidencias');

			await downloadFileBase64(toExcel, 'xlsx', filName)
				.then(() => {
					dispatchLoading({ type: LOADING_OFF });
				})
				.catch((error) => {
					dispatchLoading({ type: LOADING_OFF });
				});
		} else {
			Toast(
				'warning',
				GeneralNames.EmptyDataMessageExport,
				GeneralNames.EmptyDataMessageBodyExport,
				'success'
			);
		}
	}
	const iconsDataPanel = [
		{
			icon: 'excel',
			title: GeneralNames.GeneralExportToExcel,
			onClick: toExcelExport,
		},
	];
	return (
		<JustifyIncidenceContextManager.Provider
			value={{
				catalogData,
				filterData,
				handleStartAndEndDate,
				forceUpdateKey,
				forceUpdateKeyTwo,
				handleSetFilterData,
				getDataQueries,
				dynamicColumns,
				tableDataQuery,
				sort,
				setSort,
				filters,
				setFilters,
				modalData,
				handleSelectionTable,
				onCloseModal,
				sortJustifyTable,
				setSortJustifyTable,
				toSend,
				setToSend,
				typeIncidents,
				onHandleCollaboratorsSelection,
				handleSetIncidences,
				onCancelJustifyIncidence,
				handleJustifyIncidence,
				onSubmitForm,
				globalFilterValue,
				onGlobalFilterChange,
				clearTextLabel,
				tableDataQueryFilterList,
				toSendModal,
				setToSendModal,
				loadingModal: contextLoading,
				clearFilters,
				onClickDate,
				undoJustification,
				iconsDataPanel,
				dataTableModalErrors,
				onCloseModalErrors,
			}}>
			{children}
		</JustifyIncidenceContextManager.Provider>
	);
};
