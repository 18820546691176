import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { MultiSelectComponent } from '../../../components/MultiSelect/MultiSelectComponent';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { incidencesToJustify, typeIncidences } from '../../../utils/utils';
import { PropTypes } from 'prop-types';

export const FilterComponent = ({ service }) => {
	const {
		catalogData,
		filterData,
		handleSetFilterData,
		forceUpdateKey,
		handleStartAndEndDate,
		forceUpdateKeyTwo,
		clearFilters,
	} = service;

	const renderMultiSelect = (
		id,
		label,
		placeholder,
		optionLabel,
		value,
		options,
		onChange,
		otherPlaceHolder
	) => {
		return (
			<MultiSelectComponent
				id={id}
				value={value}
				optionLabel={optionLabel}
				options={options}
				onChange={onChange}
				placeholder={placeholder}
				title={label}
				otherPlaceHolder={otherPlaceHolder}
			/>
		);
	};

	const renderDropComponent = (
		id,
		label,
		value,
		onChange,
		name,
		options,
		optionLabel,
		placeHolder
	) => {
		return (
			<div className='flex w-full h-18  flex-col pl-1 pr-1 lg:text-xs'>
				<div className='flex w-full h-full content-center items-center'>
					<label className=' font-bold '>{label}</label>
				</div>
				<div className='flex w-full h-full content-center items-center justify-start flex-col'>
					<Dropdown
						value={value}
						onChange={onChange}
						name={id}
						id={id}
						options={options}
						optionLabel={optionLabel}
						className={'!w-full !h-5 !flex  '}
						placeholder={placeHolder}
						disabled={options.length === 0}
					/>
				</div>
			</div>
		);
	};
	return (
		<div className='flex w-full h-full flex-col '>
			<div className='flex h-[90%] w-full  gap-y-[6px] lg:!gap-y-[8px] relative '>
				<div className='flex inset-0 flex-col overflow-auto absolute'>
					<div className='flex w-full h-18  flex-col pl-1 pr-1 '>
						<div className='flex w-full h-full content-center items-center'>
							<label className=' font-bold text-sm lg:!text-xs'>
								{'Fecha Inicial'}
							</label>
						</div>
						<div className='flex w-full h-full content-center items-center justify-start flex-col'>
							<Calendar
								key={forceUpdateKey}
								showButtonBar
								id='startDate'
								className={
									' !border-none !outline-none !p-0 !text-base !font-input-font-family !w-full lg:!text-xs'
								}
								value={filterData.startDate}
								onChange={handleStartAndEndDate}
								todayButtonClassName='hidden'
								dateFormat='dd-MM-yy'
								showIcon
								placeholder={'Seleccione fecha inicial'}
								locale='es'
								name='startDate'
							/>
						</div>
					</div>
					<div className='flex w-full h-18  flex-col p-1 '>
						<div className='flex w-full h-full content-center items-center'>
							<label className=' font-bold text-sm lg:!text-xs '>
								{'Fecha Final'}
							</label>
						</div>
						<div className='flex w-full h-full content-center items-center justify-start flex-col'>
							<Calendar
								key={forceUpdateKeyTwo}
								showButtonBar
								id='endDate'
								className={
									' !border-none !outline-none !p-0 !text-base !font-input-font-family !w-full lg:!text-xs'
								}
								value={filterData.endDate}
								onChange={handleStartAndEndDate}
								todayButtonClassName='hidden'
								dateFormat='dd-MM-yy'
								showIcon
								placeholder={'Seleccione fecha final'}
								locale='es'
								name='endDate'
							/>
						</div>
					</div>
					{renderDropComponent(
						'typePeriods',
						'Tipos de Periodos',
						filterData.typePeriods,
						handleSetFilterData,
						'name',
						catalogData.periodType,
						'name',
						catalogData.periodType.length > 0
							? 'Todos los tipos de periodos'
							: ' No se encontraron periodos'
					)}
					<div className='flex w-full h-18'>
						{renderMultiSelect(
							'workCenters',
							'Centros de trabajo',
							catalogData.workCenter.length > 0
								? 'Todos los centros de trabajo'
								: ' No se encontraron Centros de trabajo',
							'name',
							filterData.workCenters,
							catalogData.workCenter,
							handleSetFilterData,
							'Todos los centros de trabajo'
						)}
					</div>
					<div className='flex w-full h-18'>
						{renderMultiSelect(
							'shifts',
							'Turnos',
							catalogData.shift.length > 0
								? 'Todos los turnos'
								: ' No se encontraron turnos',
							'name',
							filterData.shifts,
							catalogData.shift,
							handleSetFilterData,
							'Todos los turnos'
						)}
					</div>
					<div className='flex w-full h-18'>
						{renderMultiSelect(
							'departments',

							'Departamentos',
							catalogData.department.length > 0
								? 'Todos los departamentos'
								: ' No se encontraron departamentos',
							'name',
							filterData.departments,
							catalogData.department,
							handleSetFilterData,

							'Todos los departamentos'
						)}
					</div>
					<div className='flex w-full h-18'>
						{renderMultiSelect(
							'positions',
							'Puestos',
							catalogData.position.length > 0
								? 'Todos los puestos'
								: 'No se encontraron puestos',
							'name',
							filterData.positions,
							catalogData.position,
							handleSetFilterData,

							'Todos los puestos'
						)}
					</div>
					<div className='flex w-full h-18'>
						{renderMultiSelect(
							'collaborators',
							'Colaboradores',
							catalogData.employee.length > 0
								? 'Todos los colaboradores'
								: 'No se encontraron colaboradores',
							'name',
							filterData.collaborators,
							catalogData.employee,
							handleSetFilterData,
							'Todos los colaboradores'
						)}
					</div>
					<div className='flex w-full h-18'>
						{renderMultiSelect(
							'typeIncidents',

							'Tipos de incidencia',
							typeIncidences.length > 0
								? 'Todos los tipos de incidencias'
								: 'No se encontraron incidencias',
							'name',
							filterData.typeIncidents,
							incidencesToJustify,
							handleSetFilterData,

							'Todos los tipos de incidencias'
						)}
					</div>
				</div>
			</div>
			<div className='flex w-full h-12 justify-end content-end items-end  '>
				<div className='flex w-full h-full  flex-row py-1 pl-[4px] pr-[4px] content-center items-center justify-between'>
					<Button
						label='Limpiar'
						className='!flex !w-28 !h-full !bg-white !text-black !text-xs lg:!w-[68px]'
						aria-label='cancel'
						onClick={() => clearFilters()}
					/>
					<Button
						label='Consultar'
						className='!flex !w-28 !h-full !text-xs lg:!w-[68px]'
						aria-label='Submit'
						type='submit'
						onClick={() => service.getDataQueries()}
					/>
				</div>
			</div>
		</div>
	);
};

FilterComponent.propTypes = {
	service: PropTypes.object,
};
