import { useContext } from 'react';
import { StoreContext } from '../../business/Provider';
import { LOADING_OFF, LOADING_ON } from '../../business/constants';
import { formatDateToYYYYMMDD, generateUrlBase } from '../../utils/utils';
import useApi from '../api';
import { Toast } from '../../components/toast';

export const useJustifyIncidences = () => {
	const context = useContext(StoreContext);

	const {
		dispatchLoading,
		getJustifyIncidencesContextState,
		getJustifyIncidencesTypesContextState,
		postJustifyIncidencesContextState,
		postUndoJustifyIncidencesContextState,
	} = context;
	const {
		getJustifyIncidencesInit_DP,
		getJustifyIncidencesSuccess_DP,
		getJustifyIncidencesError_DP,
	} = getJustifyIncidencesContextState;

	const {
		getJustifyIncidencesTypesInit_DP,
		getJustifyIncidencesTypesSuccess_DP,
		getJustifyIncidencesTypesError_DP,
	} = getJustifyIncidencesTypesContextState;

	const {
		postJustifyIncidencesInit_DP,
		postJustifyIncidencesError_DP,
		postJustifyIncidencesClear_DP,
	} = postJustifyIncidencesContextState;

	const {
		postUndoJustifyIncidencesInit_DP,
		postUndoJustifyIncidencesSuccess_DP,
		postUndoJustifyIncidencesError_DP,
	} = postUndoJustifyIncidencesContextState;

	const { genericApiCall } = useApi();
	const getJustifyIncidence = async (data) => {
		getJustifyIncidencesInit_DP();
		dispatchLoading({ type: LOADING_ON });
		const dataToSend = JSON.stringify(data);
		const dataToBase64 = btoa(dataToSend);
		const baseUrl = generateUrlBase(
			'reports/justify-incidence?filters=' + dataToBase64
		);
		data.startDate = formatDateToYYYYMMDD(data.startDate);
		data.endDate = formatDateToYYYYMMDD(data.endDate);

		return await genericApiCall(baseUrl, 'POST', dataToBase64, {}, {})
			.then((result) => {
				const payload = result.data.results;

				if (result.status === 200) {
					const _payload = payload.map((item) => {
						return {
							...item,
							...item.incidences,
						};
					});
					console.log('payload', _payload);
					getJustifyIncidencesSuccess_DP(_payload);
				}
				return result;
			})
			.catch((error) => {
				Toast('warning', error.message, '', error);
				dispatchLoading({ type: LOADING_OFF });
				getJustifyIncidencesError_DP(error);
			})
			.finally(() => {
				dispatchLoading({ type: LOADING_OFF });
			});
	};

	const getJustifyTypes = async () => {
		getJustifyIncidencesTypesInit_DP();
		dispatchLoading({ type: LOADING_ON });
		const baseUrl = generateUrlBase('operations/incidences/justify-types');
		return await genericApiCall(baseUrl, 'GET')
			.then((result) => {
				const payload = result.data.data;
				if (result.status === 200) {
					getJustifyIncidencesTypesSuccess_DP(payload);
				}
			})
			.catch((error) => {
				console.log('Error', error);
				Toast('warning', error.message, '', error);
				dispatchLoading({ type: LOADING_OFF });
				getJustifyIncidencesTypesError_DP(error);
			})
			.finally(() => {
				dispatchLoading({ type: LOADING_OFF });
			});
	};

	const postJustifyIncidences = async (_listIncidences, filterModal) => {
		postJustifyIncidencesInit_DP();
		dispatchLoading({ type: LOADING_ON });
		const baseUrl = generateUrlBase('operations/incidences/justify');
		return await genericApiCall(baseUrl, 'POST', _listIncidences)
			.then((result) => {
				console.log('result');
				if (result.status === 200) {
					Toast('success', 'Justificación aplicada');
				} else if (result.status === 207) {
					postJustifyIncidencesError_DP(result.data.errorData);
					Toast(
						'warning',
						'Se encontraron algunos errores al aplicar los justificantes'
					);
				}
				return getJustifyIncidence(filterModal);
			})
			.catch((error) => {
				Toast('error', error.message, '', error);
				dispatchLoading({ type: LOADING_OFF });
				postJustifyIncidencesError_DP(error);
			})
			.finally(() => {
				dispatchLoading({ type: LOADING_OFF });
			});
	};
	const undoJustifyIncidences = async (_listIncidences, filterModal) => {
		postUndoJustifyIncidencesInit_DP();
		dispatchLoading({ type: LOADING_ON });
		const baseUrl = generateUrlBase('operations/incidences/justify');
		return await genericApiCall(baseUrl, 'DELETE', _listIncidences)
			.then((result) => {
				console.log('result');
				if (result.status === 200) {
					postUndoJustifyIncidencesSuccess_DP(result.data);
					Toast('success', 'Se han eliminado los justificantes correctamente');
				} else if (result.status === 207) {
					postUndoJustifyIncidencesError_DP(result.data.errorData);
					Toast(
						'warning',
						'Se encontraron algunos errores al deshacer los justificantes.'
					);
				}
				return getJustifyIncidence(filterModal);
			})
			.catch((error) => {
				Toast('error', error.message, '', error);
				dispatchLoading({ type: LOADING_OFF });
				postUndoJustifyIncidencesError_DP(error);
			})
			.finally(() => {
				dispatchLoading({ type: LOADING_OFF });
			});
	};

	return {
		getJustifyIncidence,
		getJustifyTypes,
		undoJustifyIncidences,
		postJustifyIncidences,
	};
};
