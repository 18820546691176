import { actionTypesOperations } from '../../constans/operation';

export const getJustifyIncidencesTypes_IS = {
	data: null,
	error: null,
	loading: false,
	isLoadData: false,
};
export const getJustifyIncidencesTypesRed = (state, actions) => {
	switch (actions.type) {
		case actionTypesOperations.GET_JUSTIFY_INCIDENCES_TYPES.BASE:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
				isLoadData: false,
			};
		case actionTypesOperations.GET_JUSTIFY_INCIDENCES_TYPES.ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
				data: null,
				isLoadData: false,
			};
		case actionTypesOperations.GET_JUSTIFY_INCIDENCES_TYPES.SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				data: actions.payload,
				isLoadData: true,
			};
		case actionTypesOperations.GET_JUSTIFY_INCIDENCES_TYPES.CLEAR:
			return {
				...state,
				data: null,
				loading: false,
				error: null,
				errorShow: false,
				isLoadData: false,
			};
		default:
			return state;
	}
};
